// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 120rem;

:root {
  // base
  --font-family: "Montserrat", sans-serif;
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;

  // colors
  --clr-default-100: #fff;
  --clr-default-200: rgba(255, 255, 255, 0.9);
  --clr-default-800: #111;
  --clr-default-900: #000;
  --clr-primary-100: rgba(255, 255, 255, 0.15);
  --clr-primary-400: #FCE247;
  --clr-primary-600: #07512F;
  --clr-primary-700: #096137;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-size: 1.6rem;
  color: var(--clr-default-200);
  background-color: var(--clr-default-800);

  &.lock {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

/* stylelint-disable no-invalid-position-at-import-rule */

// Libs
@import "./libs/swiper";

// Libs

// Components - UI
@import "./ui";

// Components - UI

// Components - UI
@import "./components/ui/logo";
@import "./components/ui/burger";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";

// Components - Common

// Components - Pages
@import "./components/indexPage/hero";
@import "./components/indexPage/info";
@import "./components/indexPage/app";
@import "./components/indexPage/mobile";
@import "./components/indexPage/desktop";
@import "./components/indexPage/review";
@import "./components/indexPage/register";
@import "./components/indexPage/games";
@import "./components/indexPage/slots";
@import "./components/indexPage/bonus";
@import "./components/indexPage/vip";
@import "./components/indexPage/licenties";
@import "./components/indexPage/faq";
@import "./components/indexPage/vpn";
@import "./components/indexPage/deposit";
@import "./components/indexPage/slider";
@import "./components/indexPage/conclusion";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
