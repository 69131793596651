.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-pointer-events {
  touch-action: pan-y;
  &.swiper-vertical {
    touch-action: pan-x;
  }
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.swiper-autoheight {
  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}
.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

/* CSS Mode */
.swiper-css-mode {
  > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start;
  }
}
.swiper-horizontal.swiper-css-mode {
  > .swiper-wrapper {
    scroll-snap-type: x mandatory;
  }
}
.swiper-vertical.swiper-css-mode {
  > .swiper-wrapper {
    scroll-snap-type: y mandatory;
  }
}
.swiper-centered {
  > .swiper-wrapper::before {
    content: '';
    flex-shrink: 0;
    order: 9999;
  }
  &.swiper-horizontal {
    > .swiper-wrapper > .swiper-slide:first-child {
      margin-inline-start: var(--swiper-centered-offset-before);
    }
    > .swiper-wrapper::before {
      height: 100%;
      width: var(--swiper-centered-offset-after);
    }
  }
  &.swiper-vertical {
    > .swiper-wrapper > .swiper-slide:first-child {
      margin-block-start: var(--swiper-centered-offset-before);
    }
    > .swiper-wrapper::before {
      width: 100%;
      height: var(--swiper-centered-offset-after);
    }
  }

  > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: center center;
  }
}
