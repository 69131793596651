.desktop {
  &__wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 0.8rem;
    padding-top: 5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    padding-bottom: 5rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: url(../img/desktop/bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;

      @media (max-width: em(768)) {
        background-size: 250% 100%;
      }
    }
  }

  &__title {
    padding-bottom: 2.5rem;
    font-weight: 700;
    font-size: fluid-font(4.6rem, 3.6rem);
    line-height: 125%;
    color: var(--clr-primary-400);
  }

  &__text {
    padding-bottom: 5rem;
    font-size: 1.6rem;
    line-height: 145%;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
