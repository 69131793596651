.hero {
  padding-top: 9rem;

  @media (max-width: em(992)) {
    padding-top: 6rem;
  }

  @media (max-width: em(768)) {
    padding-top: 3rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__content {
    margin-right: 3rem;
    min-width: 57rem;

    @media (max-width: em(1200)) {
      min-width: initial;
    }
  }

  &__title {
    padding-bottom: 1.6rem;
    font-size: fluid-font(6rem, 3.6rem);
    line-height: 115%;
    color: var(--clr-primary-400);
  }

  &__subtitle {
    padding-bottom: 5rem;
  }

  &__img {
    flex-shrink: 0;

    @media (max-width: em(1200)) {
      display: none;
    }
  }
}
