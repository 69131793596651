.register {
  padding-bottom: 14rem;

  @media (max-width: em(992)) {
    padding-bottom: 10rem;
  }

  @media (max-width: em(768)) {
    padding-bottom: 3rem;
  }

  &__subtitle {
    padding-bottom: 5rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__content {
    margin-right: 3rem;
    min-width: 57rem;

    @media (max-width: em(1200)) {
      margin-right: 0;
      min-width: initial;
    }
  }

  &__items {
    counter-reset: num;
    list-style-type: none;
    margin-bottom: 6rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 9.3rem;
    min-height: 7.5rem;
    line-height: 145%;
    color: var(--clr-default-100);

    &::before {
      counter-increment: num;
      content: counter(num);
      position: absolute;
      left: 0;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 7.4rem;
      height: 7.4rem;
      font-weight: 700;
      font-size: 3.5rem;
      color: var(--clr-primary-700);
      background-color: var(--clr-primary-400);
      transform: translateY(-50%);

      @media (max-width: em(575)) {
        width: 6.4rem;
        height: 6.4rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    @media (max-width: em(575)) {
      padding-left: 8.3rem;
    }
  }

  &__img {
    flex-shrink: 0;

    @media (max-width: em(1200)) {
      display: none;
    }
  }
}
