.games {
  padding-top: 14rem;
  padding-bottom: 14rem;
  background-color: var(--clr-primary-600);

  @media (max-width: em(992)) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @media (max-width: em(768)) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &__title {
    padding-bottom: 2.5rem;
  }

  &__text-wrap {
    padding-bottom: 6rem;
    line-height: 145%;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__items {
    display: grid;
    grid-gap: 2.6rem;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));

    @media (max-width: em(992)) {
      grid-gap: 1.5rem;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border-radius: 1.6rem;
    min-height: 43rem;
    background-color: var(--clr-default-800);
  }

  &__img {
    position: relative;
    padding-bottom: 67%;
    width: 100%;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__item-title {
    padding-bottom: 1.5rem;
    font-size: fluid-font(2.2rem, 1.8rem);
    text-align: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 2.5rem;
    padding-left: 1.6rem;
    height: 100%;
  }

  &__name {
    padding-bottom: 0.8rem;
    font-size: fluid-font(1.8rem, 1.6rem);
  }

  &__cost {
    padding-bottom: 1.6rem;
    font-size: fluid-font(1.8rem, 1.6rem);
    color: var(--clr-primary-400);
  }

  &__link {
    margin: 0 auto;
    margin-bottom: 1.6rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    min-width: 17.5rem;
    min-height: 5rem;
  }

  &__text {
    color: #c6c6c6;
  }

  &__bottom {
    margin-top: 6rem;
    line-height: 145%;

    @media (max-width: em(575)) {
      margin-top: 3rem;
    }
  }
}
