.slots {
  &__subtitle {
    padding-bottom: 6rem;
  }

  &__banner {
    position: relative;
    border-radius: 1.2rem;
    width: 100%;
    height: 100%;
    min-height: 50rem;
    background-image: url(../img/slots/bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 1;
      width: 100rem;
      height: 54.5rem;
      background-image: url(../img/slots/777.png);
      background-position: center;
      background-repeat: no-repeat;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      right: -140px;
      top: -140px;
      width: 65.5rem;
      height: 72.8rem;
      background-image: url(../img/slots/mobile.png);
      background-repeat: no-repeat;

      @media (max-width: em(1200)) {
        display: none;
      }
    }

    @media (max-width: em(992)) {
      display: none;
    }
  }
}
