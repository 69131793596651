.app {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8rem;

    @media (max-width: em(575)) {
      padding-bottom: 2rem;
    }
  }

  &__content {
    max-width: 58rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__subtitle {
    padding-bottom: 5rem;

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }
  }

  &__items {
    list-style: disc;
    margin-left: 2.5rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }

  &__img {
    @media (max-width: em(992)) {
      display: none;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
  }
}
