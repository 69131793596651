.slider {
  &__title {
    position: relative;
    font-size: fluid-font(6rem, 3.6rem);
    line-height: 125%;
    color: var(--clr-primary-400);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -50px;
      z-index: -1;
      width: 20.3rem;
      height: 16rem;
      background-image: url(../img/slider/decor.svg);
      background-repeat: no-repeat;

      @media (max-width: em(575)) {
        display: none;
      }
    }

    @media (max-width: em(575)) {
      padding-bottom: 2rem;
    }
  }

  &__bottom {
    padding-left: 1.5rem;

    @media (max-width: em(1200)) {
      padding-right: 1.5rem;
    }
  }

  &__swiper {
    padding-top: 5rem;
    padding-left: calc((100% - 120rem)/2);

    @media (max-width: em(1200)) {
      left: initial;
      min-width: 100%;
    }

    @media (max-width: em(575)) {
      padding-top: 0;
    }
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 117rem;

    @media (max-width: em(575)) {
      flex-direction: column;
    }
  }

  &__content {
    padding-top: 6rem;

    @media (max-width: em(575)) {
      padding-top: 3rem;
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.8rem;
    padding-top: 2.8rem;
    padding-right: 2.5rem;
    padding-bottom: 2.8rem;
    padding-left: 2.5em;
    min-height: 17.8rem;
    background-color: var(--clr-primary-600);

    @media (max-width: em(575)) {
      padding-top: 1.8rem;
      padding-right: 1.5rem;
      padding-bottom: 1.8rem;
      padding-left: 1.5em;
    }
  }

  &__slide-title {
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 150%;
    color: var(--clr-default-100);
  }

  &__stars {
    display: flex;
    margin-top: 1rem;

    span {
      position: relative;
      width: 1.77rem;
      height: 1.69rem;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1.77rem;
        height: 1.69rem;
        background-image: url(../img/slider/star.svg);
        background-repeat: no-repeat;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    min-width: 12rem;
  }

  &__button-prev, .slider__button-next {
    position: relative;
    border: 1px solid var(--clr-primary-600);
    width: 4.8rem;
    height: 4.8rem;
    transition: background-color var(--transition);

    &:hover {
      background-color: var(--clr-primary-600);
      transition: background-color var(--transition);
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.9rem;
      height: 1.4rem;
      background-size: 0.9rem 1.4rem;
      background-repeat: no-repeat;
    }
  }

  &__button-prev {
    &::after {
      background-image: url(../img/slider/arrow.svg);
      transform: translate(-50%, -50%);
    }
  }

  &__button-next {
    &::after {
      background-image: url(../img/slider/arrow.svg);
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}
