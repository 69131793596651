.vpn {
  &__subtitle {
    padding-bottom: 6rem;
  }

  &__item {
    position: relative;
    padding-left: 4rem;
    font-size: fluid-font(2rem, 1.8rem);
    color: var(--clr-default-100);

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(../img/vpn/done.svg);
      background-repeat: no-repeat;
    }
  }
}
